<template>
  <div>
    <div class="header-modal">
        <a type="button" @click="$emit('close')">
            <img src="@/assets/arrow-left-icon.svg" alt="Назад">
        </a>
        <h2>Новая трата</h2>
    </div>
    <div class="margin-30">
        <form @submit.prevent="addExpense">
            <label for="title">Описание:</label><br>
            <input v-model="title" id="title" type="text" required /><br>

            <label for="amount">Сумма:</label><br>
            <input v-model="amount" id="amount" type="number" required /><br>

            <label for="date">Дата:</label><br>
            <input v-model="date" id="date" type="date" required /><br>

            <label for="category">Категория:</label><br>
            <select v-model="selectedCategoryId" id="category">
                <option v-for="category in categories" :key="category.id" :value="category.id">
                    {{ category.name }}
                </option>
            </select><br>

            <button type="submit" class="primary margin-30">Добавить</button><br><br>
        </form>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        categories: { // Парсинг списка категорий
            type: Array,
            required: false
        }
    },
    data() {
        return {
            title: '',
            amount: null,
            date: '',
            selectedCategoryId: null // ID категории
        };
    },
    methods: {
        addExpense() {
            const newExpense = {
                title: this.title,
                amount: this.amount,
                date: this.date,
                categoryId: this.selectedCategoryId || null // Если не выбрана - null
            }
            this.$emit('add-expense', newExpense); // Передача в родительский компонент
            this.resetForm();
        },
        resetForm() {
            this.title = '';
            this.amount = null;
            this.date = '';
            this.selectedCategoryId = null;
        }
    }
}
</script>