<template>
    <div v-for="(group, month) in sortedGropedExpenses" :key="month" class="expense-group">
        <div class="expense-group-header">
            <h2>{{ formatMonth(month) }}</h2>
            <div class="expense-group-badge">
                <span class="expense-group-total">- {{ calculateTotal(group) }}</span>
                <img src="@/assets/coin-icon.svg" alt="Сумма">
            </div>
        </div>
        <ExpenseCard
            v-for="expense in sortedExpenses(group)"
            :key="expense.id"
            :expense="expense"
            :categories="categories"
        />
    </div>
</template>

<script>
import ExpenseCard from './ExpenseCard.vue';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

export default {
    name: 'ExpenseList',
    components: {
        ExpenseCard
    },
    props: {
        expenses: {
            type: Array,
            required: true
        },
        categories: {
            type: Array,
            required: false
        }
    },
    computed: {
        // Группировка по месяцам
        groupedExpenses() {
            return this.expenses.reduce((groups, expense) => {
                const month = format(new Date(expense.date), 'yyyy-MM', { locale: ru });
                
                if (!groups[month]) {
                    groups[month] = [];
                }
                groups[month].push(expense);
                return groups;
            }, {});
        },
        // Сортировка месяцев
        sortedGropedExpenses() {
            const groups = this.groupedExpenses;
            const sortedMonths = Object.keys(groups).sort((a, b) => new Date(a) - new Date(b)); // Сортировка по дате
            return sortedMonths.reduce((sortedGroups, month) => {
                sortedGroups[month] = groups[month];
                return sortedGroups;
            }, {});
        }
    },
    methods: {
        // Форматирование наименовая месяца
        formatMonth(month) {
            const date = new Date(`${month}-01`); // Ключ yyyy-MM в дату
            const formattedDate = format(date, 'LLLL`yy', { locale: ru });
            return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        },
        // Сортировка трат в месяце по дате
        sortedExpenses(expenses) {
            return expenses.sort((a, b) => new Date(a.date) - new Date(b.date));
        },
        calculateTotal(expenses) {
            return expenses.reduce((total, expense) => total + expense.amount, 0);
        }
    }
}
</script>

<style scoped>
.expense-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 20px;
}

.expense-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.expense-group-badge {
    border: 2px solid #EBEBEB;
    border-radius: 50px;
    background-color: #EBEBEB;
    padding: 4px 6px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
}

.expense-group-badge img{
    height: 20px;
    width: auto;
}

.expense-group-total {
    font-size: 16px;
    /* font-weight: bold; */
    margin-right: 4px;
}
</style>