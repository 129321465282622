export const categories = [
    { id: 1, name: 'Жильё' },
    { id: 2, name: 'Продукты' },
    { id: 3, name: 'Транспорт' },
    { id: 4, name: 'Покупки' },
    { id: 5, name: 'Путешествия' },
    { id: 6, name: 'Дети' },
    { id: 7, name: 'Здоровье и спорт' },
    { id: 8, name: 'Развлечения' },
    { id: 9, name: 'Кафе и рестораны' },
    { id: 10, name: 'Красота' },
    { id: 11, name: 'Обучение' },
    { id: 12, name: 'Подписки и связь' },
    { id: 13, name: 'Налоги и штрафы' },
    { id: 14, name: 'Подарки' },
    { id: 15, name: 'Кредит' },
    { id: 16, name: 'Другое' },
];