<template>
  <header>
    <img src="@/assets/logo.svg" alt="Траты – запланируй свои траты" class="logo">
    <button class="header-button" ref="toggleButton" @click="toggleMenu">
      <img src="@/assets/more-icon.svg" alt="Меню">
    </button>
  </header>

  <div id="content">
    <!-- Если нет трат -->
     <p v-if="expenses.length === 0" class="no-expenses-message">Пока нет добавленных трат. <br> Скорее нажимай на кнопку "+" внизу экрана</p>

    <!-- Если есть – выводим список -->
    <ExpenseList 
      :expenses="expenses" 
      :categories="categories"
    />
  </div>

  <div v-if="showMenu" class="toggle-menu" ref="toggleMenu">
    <ul>
      <li @click="openSettings">Настройки</li>
      <li @click="clearExpenses" class="danger">Удалить все траты</li>
    </ul>
  </div>

  <button v-if="!showModal" class="add-button" @click="showModal = true">
    <img src="@/assets/plus-icon.svg" alt="Добавить" class="add-icon">
  </button>

  <!-- Попап для добавления расхода -->
  <div v-if="showModal" class="modal">
    <div class="modal-content">
      <ExpenseForm 
        @add-expense="addExpense" 
        @close="closeModal" 
        :categories="categories"
      />
    </div>
  </div>
</template>

<script>
import ExpenseForm from './components/ExpenseForm.vue';
import ExpenseList from './components/ExpenseList.vue';
import { categories } from './data/categories';

export default {
  components: {
    ExpenseForm,
    ExpenseList
  },
  data() {
    return {
      expenses: [],
      categories: categories,
      showModal: false,
      showMenu: false
    }
  },
  created() {
    // Список трат из LocalStorage при загрузке
    const savedExpenses = localStorage.getItem('expenses');
    if (savedExpenses) {
      this.expenses = JSON.parse(savedExpenses);
    }
    // Обработчик кликов
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    addExpense(newExpense) {
      this.expenses.push(newExpense);
      localStorage.setItem('expenses', JSON.stringify(this.expenses)); // Добавление трат в LocalStorage
      this.showModal = false; // Закрытие попапа после добавления
    },
    closeModal() {
      this.showModal = false; // Закрытие попапа по кнопке
    },
    clearExpenses() {
      this.expenses = [];
      localStorage.removeItem('expenses');
      this.showMenu = false;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    handleClickOutside(event) {
      // Проверка был ли клик в тоггле
      const toggleMenu = this.$refs.toggleMenu;
      const toggleButton = this.$refs.toggleButton;
      if (toggleMenu && !toggleMenu.contains(event.target) && !toggleButton.contains(event.target)){
        this.showMenu = false ; // Закрыть меню, если клик вне меню
      }
    },
    openSettings() {
      alert('Настройки будут позже');
      this.showMenu = false;
    }
  }
}
</script>

<style>
.no-expenses-message {
  text-align: center;
  font-size: 18px;
  color: #ADADAD;
  margin-top: 20px;
}
</style>